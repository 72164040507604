#loading {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: none;
    background: url(./loading-min.gif) center no-repeat #fff;
}

.modal-backdrop {
    z-index: -1 !important;
    display:none !important;
}

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    border: none;
}

#content {
    width: calc(100% - 250px);
    padding: 20px;
    min-height: 100vh;
    transition: all 0.2s;
    border: none;
}

/* menu samping */

#sidebar {
    min-width: 250px;
    max-width: 250px;
    box-shadow: 3px 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.2s;
    background-size: cover;
    position: relative;
    color: rgb(19, 19, 19);
    font-size: 14px;
    line-height: 24px;
    overflow-y: auto;
    background: rgb(70, 44, 160);
    background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

thead {
    color: #fefefe;
    background: rgb(70, 44, 160);
    background: linear-gradient(270deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
}

th {
    font-size: 11px !important;
}

td {
    font-size: 11px !important;
}

#sidebar .sidebar-header {
    padding: 20px;
    color: #fefefe;
    overflow-y: scroll;
}

#sidebar ul.components {
    padding: 0 10px 0 0;
    border-bottom: 1px solid rgba(84, 58, 183, 1);
}

#sidebar ul p {
    color: #CC90F4;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
    margin: 2px 4px;
    text-align: left;
    color: #fefefe;
    text-decoration: none;
}

#sidebar ul li a:hover {
    color: rgba(84, 58, 183, 1);
    background-color: #f7f7f7bd;
    border-radius: 0 25px 25px 0;
}

#sidebar ul li.active>a, a[aria-expanded="true"] {
    color: rgba(84, 58, 183, 1);
    background-color: #f7f7f7bd;
    border-radius: 0 25px 25px 0;
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    /* background-color: rgba(192, 192, 192, 0.8); */
}

#sidebar.active {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
}

#sidebar.active .nama-menu {
    display: none;
}

#sidebar .sidebar-header .logo-panjang {
    display: block;
}

#sidebar .sidebar-header .logo {
    display: none;
}

#sidebar.active .sidebar-header .logo {
    display: block;
}

#sidebar.active .sidebar-header .logo-panjang {
    display: none;
}

#sidebar.active ul li a {
    padding: 20px 10px;
    text-align: center;
    font-size: 18px;
}

#sidebar.active ul li a i {
    margin-right: 0;
    display: block;
    font-size: 18px;
}

#sidebar.active .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
}

.transbox {
    height: calc(100vh - 200px);
    background-color: transparent;
}

/* div card main */

.main-card-body {
    background-color: #FEFEFE;
    color: #fff;
    padding: 15px;
    border-radius: 15px;
    overflow-x: hidden;
}

#tampil {
    background-color: #FEFEFE;
    color: black;
}

.card-header {
    font-size: 18px !important;
}

/* alert */

#divPesan {
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 30%;
}

.alert {
    position: relative;
}

.alert .close {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 1em;
}

/* lazzy load */

.mid {
    min-height: 24px;
    max-width: 180px;
    margin-bottom: 10px;
    border-radius: 4px
}

.mid-big {
    min-height: 40px;
    max-width: 560px;
    margin-bottom: 15px;
    border-radius: 4px
}

.mid-big-subtitle {
    min-height: 24px;
    max-width: 960px;
    margin-bottom: 20px;
    border-radius: 4px
}

/* Animacao background */

.lazyColor {
    opacity: 0.5;
    background: linear-gradient(135deg, rgba(211, 223, 232, .5) 30%, rgba(169, 184, 197, .5) 38%, rgba(168, 182, 193, .6) 41%, rgba(168, 182, 193, .6) 50%, rgba(168, 182, 193, .6) 50%, rgba(169, 184, 197, .6) 51%, rgba(211, 223, 232, .6) 57%, rgba(211, 223, 232, .6) 80%, rgba(211, 223, 232, .6) 80%);
    background-size: 1200% 1200%;
    -webkit-animation: AnimationName 1s ease infinite reverse;
    -moz-animation: AnimationName 1s ease infinite reverse;
    animation: AnimationName 1s ease infinite reverse;
}

@-webkit-keyframes AnimationName {
    0% {
        background-position: 0% 92%
    }

    50% {
        background-position: 100% 9%
    }

    100% {
        background-position: 0% 92%
    }
}

@-moz-keyframes AnimationName {
    0% {
        background-position: 0% 92%
    }

    50% {
        background-position: 100% 9%
    }

    100% {
        background-position: 0% 92%
    }
}

@keyframes AnimationName {
    0% {
        background-position: 0% 92%
    }

    100% {
        background-position: 100% 9%
    }
}

/* task */

.li-drag button {
    display: none;
}

.li-drag:hover button {
    display: block;
}

.li-drag:hover {
    background-color: #f7f7f7;
}

.btn-edit:hover .material-icons {
    font-size: 18px;
}

.btn-edit .material-icons {
    font-size: 14px;
}

.btn-default {
    background: linear-gradient(270deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
    color: #fefefe !important;
    border-radius: 5px;
}

.btn-default:hover {
    background: transparent;
    border: solid rgba(84, 58, 183, 1) 1px !important;
    color: rgba(84, 58, 183, 1) !important;
}

.btn-outline-default {
    background: transparent;
    color: rgba(84, 58, 183, 1) !important;
    border: solid rgba(84, 58, 183, 1) 1px !important;
    border-radius: 5px;
}

.btn-outline-default:hover {
    background: linear-gradient(270deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
    color: #fefefe !important;
}


/* scrollbar */

.isi-data {
    width: auto;
    overflow-x: hidden;
    overflow-y: scroll;
}

.pagination {
    padding-top: 20px;
}

/* width */

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px grey;
    border-radius: 5px;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background: rgb(70, 44, 160);
    border-radius: 5px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #9c27b0;
}

.material-icons {
    display: inline-flex;
    vertical-align: top;
}

.table {
    font-size: 12px;
}

.nowrap {
    white-space: nowrap;
}

.div-card-sum {
    overflow-x: auto;
}

.card-sum {
    min-width: 150px;
    min-height: 90px;
    background-image: url("./Card.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top:16px;
    padding-bottom:10px;
    padding-left: 12px;
    color: #FFFFFF;
}

/* START TOOLTIP STYLES */

[tooltip] {
    position: relative;
    /* opinion 1 */
}

/* Applies to all tooltips */

[tooltip]::before, [tooltip]::after {
    text-transform: none;
    /* opinion 2 */
    font-size: 0.9em;
    /* opinion 3 */
    line-height: 1;
    user-select: none;
    pointer-events: none;
    position: absolute;
    display: none;
    opacity: 0;
}

[tooltip]::before {
    content: "";
    border: 5px solid transparent;
    /* opinion 4 */
    z-index: 1001;
    /* absurdity 1 */
}

[tooltip]::after {
    content: attr(tooltip);
    /* magic! */
    /* most of the rest of this is opinion */
    font-family: Tahoma, sans-serif;
    text-align: center;
    /* 
    Let the content set the size of the tooltips 
    but this will also keep them from being obnoxious
    */
    min-width: 3em;
    max-width: 21em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1ch 1.5ch;
    border-radius: 0.3ch;
    box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
    background: linear-gradient(270deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
    color: #fff;
    z-index: 1000;
    /* absurdity 2 */
}

/* Make the tooltips respond to hover */

[tooltip]:hover::before, [tooltip]:hover::after {
    display: block;
}

/* don't show empty tooltips */

[tooltip=""]::before, [tooltip=""]::after {
    display: none !important;
}

/* FLOW: UP */

[tooltip]:not([flow])::before, [tooltip][flow^="up"]::before {
    bottom: 100%;
    border-bottom-width: 0;
    border-top-color: rgb(70, 44, 160);
}

[tooltip]:not([flow])::after, [tooltip][flow^="up"]::after {
    bottom: calc(100% + 5px);
}

[tooltip]:not([flow])::before, [tooltip]:not([flow])::after, [tooltip][flow^="up"]::before, [tooltip][flow^="up"]::after {
    left: 50%;
    transform: translate(-50%, -0.5em);
}

/* FLOW: DOWN */

[tooltip][flow^="down"]::before {
    top: 100%;
    border-top-width: 0;
    border-bottom-color: rgb(70, 44, 160);
}

[tooltip][flow^="down"]::after {
    top: calc(100% + 5px);
}

[tooltip][flow^="down"]::before, [tooltip][flow^="down"]::after {
    left: 50%;
    transform: translate(-50%, 0.5em);
}

/* FLOW: LEFT */

[tooltip][flow^="left"]::before {
    top: 50%;
    border-right-width: 0;
    border-left-color: rgb(70, 44, 160);
    left: calc(0em - 5px);
    transform: translate(-0.5em, -50%);
}

[tooltip][flow^="left"]::after {
    top: 50%;
    right: calc(100% + 5px);
    transform: translate(-0.5em, -50%);
}

/* FLOW: RIGHT */

[tooltip][flow^="right"]::before {
    top: 50%;
    border-left-width: 0;
    border-right-color: rgb(70, 44, 160);
    right: calc(0em - 5px);
    transform: translate(0.5em, -50%);
}

[tooltip][flow^="right"]::after {
    top: 50%;
    left: calc(100% + 5px);
    transform: translate(0.5em, -50%);
}

@keyframes tooltips-vert {
    to {
        opacity: 0.9;
        transform: translate(-50%, 0);
    }
}

@keyframes tooltips-horz {
    to {
        opacity: 0.9;
        transform: translate(0, -50%);
    }
}

[tooltip]:not([flow]):hover::before, [tooltip]:not([flow]):hover::after, [tooltip][flow^="up"]:hover::before, [tooltip][flow^="up"]:hover::after, [tooltip][flow^="down"]:hover::before, [tooltip][flow^="down"]:hover::after {
    animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]:hover::before, [tooltip][flow^="left"]:hover::after, [tooltip][flow^="right"]:hover::before, [tooltip][flow^="right"]:hover::after {
    animation: tooltips-horz 300ms ease-out forwards;
}

button.dropdown-item {
    font-size: 12px;
    color: black;
}

button.dropdown-item:hover {
    font-size: 12px;
    border: solid 1px;
    border-color: rgb(70, 44, 160);
    color: rgb(70, 44, 160);
}

.image-upload>input {
    display: none;
}

.form-group label {
    font-size: 12px;
}

.modal-body {
    padding: 10px 30px 10px 30px;
}

.form-control {
    border: 1px solid rgba(84, 58, 183, 1) !important;
    background-color: #FEFEFE;
    color: black;
}

.form-control:focus {
    border: 2px solid rgba(84, 58, 183, 1) !important;
    background-color: #FEFEFE;
    color: black;
}

.form-control-sc {
    border: none !important;
    border-top: 2px solid rgba(84, 58, 183, 1) !important;
    border-bottom: 2px solid rgba(84, 58, 183, 1) !important;
    border-right: 2px solid rgba(84, 58, 183, 1) !important;
    background-color: #FEFEFE;
    color: black;
}

.btn-sc {
    border: none !important;
    border-top: 2px solid rgba(84, 58, 183, 1) !important;
    border-bottom: 2px solid rgba(84, 58, 183, 1) !important;
    border-left: 2px solid rgba(84, 58, 183, 1) !important;
    background-color: #FEFEFE;
    color: black;
}

.form-control:disabled {
    border: none !important;
    border-bottom: 2px solid rgba(84, 58, 183, 1) !important;
    background-color: #FEFEFE;
    color: black;
}

.form-control:read-only {
    background-color: #d0d0d0 !important;
    color: black;
}

/* table */

.table-data {
    font-size: 20px;
}

.card-th {
    border-color: none;
    background-color: transparent;
    font-size: 14px;
}

.card-td {
    border-color: #2b3443;
    background-color: #c5c5c5;
    border-radius: 15px;
    font-size: 14px;
}

.card-td.card {
    border-color: #2b3443;
    background-color: #c5c5c5;
}

.card-td.img-action {
    width: 18px !important;
    margin-left: 7px !important;
    cursor: pointer !important;
}

/* warna */

.txt-default {
    color: #515561 !important;
}

.txt-putih {
    color: #FEFEFE !important;
}

.txt-merah {
    color: #Ed3237 !important;
}

.txt-hijau {
    color: #00A859 !important;
}

.txt-biru {
    color: #1F82DE !important;
}

.border-default {
    border: solid #515561 3px !important;
    border-radius: 10px;
}

.color-bgt-default {
    border-color: #2d343e !important;
    color: #2d343e !important;
}

.color-bgt-default:hover {
    border-color: rgb(70, 44, 160) !important;
    background-color: rgb(70, 44, 160) !important;
    color: black !important;
}

/* input foto */

.div-add-foto {
    text-align: center;
}

.img-add-profile {
    position: relative;
    border: solid #fff 5px;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    top: -100px;
}

.div-add-foto .btn-add-foto {
    position: absolute;
    border-radius: 50%;
    background: #fff;
}

/* form */

.form-lbl {
    color: rgb(70, 44, 160);
    font-size: 14px;
}

.form-card {
    color: #fff;
    border-radius: 15px;
    background-color: #2b3443;
    margin: 25px;
}

@media (max-height: 1440px) {
    .isi-data {
        height: 750px;
    }
}

@media (max-height: 768px) {
    .isi-data {
        height: 430px;
    }
}

@media (max-width: 768px) {
    #divPesan {
        bottom: 15px;
        width: 80%;
    }

    .btn-simpan-desktop {
        display: none;
    }

    .btn-simpan-mobile {
        display: block;
    }

    #sidebar.active {
        min-width: 80px;
        max-width: 80px;
        text-align: center;
        margin-left: -80px !important;
    }

    #sidebar {
        margin-left: 0;
    }

    #sidebar .sidebar-header strong {
        display: none;
    }

    #sidebar.active .sidebar-header h3 {
        display: none;
    }

    #sidebar.active .sidebar-header strong {
        display: block;
    }

    #sidebar.active ul li a {
        padding: 20px 10px;
        font-size: 0.85em;
    }

    #sidebar.active ul li a i {
        margin-right: 0;
        display: block;
        font-size: 1.8em;
        margin-bottom: 5px;
    }

    #sidebar.active ul ul a {
        padding: 10px !important;
    }

    .dropdown-toggle::after {
        top: auto;
        bottom: 10px;
        right: 50%;
        -webkit-transform: translateX(50%);
        -ms-transform: translateX(50%);
        transform: translateX(50%);
    }

    #content {
        width: 100%
    }
}

.table-calendar {
    border: solid #515561 2px !important;
    height: 100%;
    color: #fefefe;
}

.th-calendar {
    border: solid #515561 2px !important;
    color: #fefefe;
    text-align: center;
}

.td-calendar {
    border: solid #515561 2px !important;
}

.td-calendar:hover {
    background-color: #FEFEFE;
    color: #FEFEFE;
}

.td-calendar.active {
    background-color: #FEFEFE;
    color: #FEFEFE;
}

.card-calendar {
    border: solid #FEFEFE 2px !important;
    background-color: #FEFEFE;
    width: 100%;
    height: 100%;
    color: #fefefe;
}

.card-calendar:hover {
    background-color: #FEFEFE;
    color: #FEFEFE;
}

/* START TOOLTIP STYLES */

[tooltip] {
    position: relative;
    /* opinion 1 */
}

/* Applies to all tooltips */

[tooltip]::before, [tooltip]::after {
    text-transform: none;
    /* opinion 2 */
    font-size: 0.9em;
    /* opinion 3 */
    line-height: 1;
    user-select: none;
    pointer-events: none;
    position: absolute;
    display: none;
    opacity: 0;
}

[tooltip]::before {
    content: "";
    border: 5px solid transparent;
    /* opinion 4 */
    z-index: 1001;
    /* absurdity 1 */
}

[tooltip]::after {
    content: attr(tooltip);
    font-family: Tahoma, sans-serif;
    text-align: center;
    min-width: 3em;
    max-width: 21em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1ch 1.5ch;
    border-radius: 0.3ch;
    box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
    background: rgb(70, 44, 160);
    color: #fff;
    z-index: 1000;
    /* absurdity 2 */
}

/* Make the tooltips respond to hover */

[tooltip]:hover::before, [tooltip]:hover::after {
    display: block;
}

/* don't show empty tooltips */

[tooltip=""]::before, [tooltip=""]::after {
    display: none !important;
}

/* FLOW: UP */

[tooltip]:not([flow])::before, [tooltip][flow^="up"]::before {
    bottom: 100%;
    border-bottom-width: 0;
    border-top-color: rgb(70, 44, 160);
}

[tooltip]:not([flow])::after, [tooltip][flow^="up"]::after {
    bottom: calc(100% + 5px);
}

[tooltip]:not([flow])::before, [tooltip]:not([flow])::after, [tooltip][flow^="up"]::before, [tooltip][flow^="up"]::after {
    left: 50%;
    transform: translate(-50%, -0.5em);
}

/* FLOW: DOWN */

[tooltip][flow^="down"]::before {
    top: 100%;
    border-top-width: 0;
    border-bottom-color: rgb(70, 44, 160);
}

[tooltip][flow^="down"]::after {
    top: calc(100% + 5px);
}

[tooltip][flow^="down"]::before, [tooltip][flow^="down"]::after {
    left: 50%;
    transform: translate(-50%, 0.5em);
}

/* FLOW: LEFT */

[tooltip][flow^="left"]::before {
    top: 50%;
    border-right-width: 0;
    border-left-color: rgb(70, 44, 160);
    left: calc(0em - 5px);
    transform: translate(-0.5em, -50%);
}

[tooltip][flow^="left"]::after {
    top: 50%;
    right: calc(100% + 5px);
    transform: translate(-0.5em, -50%);
}

/* FLOW: RIGHT */

[tooltip][flow^="right"]::before {
    top: 50%;
    border-left-width: 0;
    border-right-color: rgb(70, 44, 160);
    right: calc(0em - 5px);
    transform: translate(0.5em, -50%);
}

[tooltip][flow^="right"]::after {
    top: 50%;
    left: calc(100% + 5px);
    transform: translate(0.5em, -50%);
}

/* KEYFRAMES */

@keyframes tooltips-vert {
    to {
        opacity: 0.9;
        transform: translate(-50%, 0);
    }
}

@keyframes tooltips-horz {
    to {
        opacity: 0.9;
        transform: translate(0, -50%);
    }
}

/* FX All The Things */

[tooltip]:not([flow]):hover::before, [tooltip]:not([flow]):hover::after, [tooltip][flow^="up"]:hover::before, [tooltip][flow^="up"]:hover::after, [tooltip][flow^="down"]:hover::before, [tooltip][flow^="down"]:hover::after {
    animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]:hover::before, [tooltip][flow^="left"]:hover::after, [tooltip][flow^="right"]:hover::before, [tooltip][flow^="right"]:hover::after {
    animation: tooltips-horz 300ms ease-out forwards;
}

.col-td {
    font-size: 20px;
}

.div-add-foto {
    text-align: left;
    margin-top: 100px;
}

.img-add-profile {
    border: solid #fff 5px;
    border-radius: 25px;
    width: 350px;
    height: 350px;
}

.div-add-foto .btn-add-foto {
    padding: 5px;
}

.profile {
    text-align: center;
}

.profile-image {
    border-radius: 50%;
    width: 50%;
    height: 50%;
    padding-bottom: 15px;
}

.profile-name {
    font-size: 16px;
    color: #fff;
}

.profile-nik {
    font-size: 12px;
    color: #fff;
}

.icon-color {
    cursor: pointer;
    background: linear-gradient(0deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.btn-notif {
    display: inline-block;
}

.btn-notif .badge {
    position: absolute;
    top: 5px;
    right: 6px;
    padding: 5px 10px;
    border-radius: 50%;
    background: red;
    color: white;
}

.img-avatar-table {
    border-radius: 50%;
    width: 41px;
    height: 41px;
    margin-left: 20px;
}

.select-active {
    border: solid #FEEAF5 3px !important;
    border-radius: 10px;
    background-color: #FEEAF5;
    color: #FF5E63;
}

.select-pending {
    border: solid #00d4ff 3px !important;
    border-radius: 10px;
    background-color: #00d4ff;
    color: rgb(70, 44, 160);
}

.select-failed {
    border: solid #00A859 3px !important;
    border-radius: 10px;
    background-color: #00A859;
    color: #FFFFFF;
}

.page-item {
    margin: 5px;
}

.col-td {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}

.txt-color-red {
    color: #Ed3237 !important;
}

.txt-color-blue {
    color: #1F82DE !important;
}

.txt-color-green {
    color: #00A859 !important;
}

.nopadding {
    padding: 0 !important;
    margin: 0 !important;
}

.card-ungu {
    background-image: linear-gradient(to right, #1D2D60, #6656DA);
    height: 150px;
    cursor: pointer;
    border: none;
}

.card-ungu:hover {
    background-image: linear-gradient(to left, #1D2D60, #6656DA);
    height: 150px;
}

.card-oren {
    background-image: linear-gradient(to right, rgb(70, 44, 160), #00d4ff);
    height: 150px;
    cursor: pointer;
    border: none;
}

.card-oren:hover {
    background-image: linear-gradient(to left, rgb(70, 44, 160), #00d4ff);
}

.card-pink {
    background-image: linear-gradient(to right, #C35184, #FD9A7D);
    height: 150px;
    cursor: pointer;
    border: none;
}

.card-pink:hover {
    background-image: linear-gradient(to left, #C35184, #FD9A7D);
}

.card-biru {
    background-image: linear-gradient(to right, #1F82DE, #4EEAE6);
    height: 150px;
    cursor: pointer;
    border: none;
}

.card-biru:hover {
    background-image: linear-gradient(to right, #4EEAE6, #1F82DE);
}

/* text warping */

.text-warping {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    -webkit-box-orient: vertical;
}

.text-warping-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    -webkit-box-orient: vertical;
}

.text-warping-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    -webkit-box-orient: vertical;
}

/* googla maps */

input.form-date {
    display: inline-block;
    position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 150px;
}


input[type="month"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 150px;
}