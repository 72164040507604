@import url(//fonts.googleapis.com/css?family=Montserrat:400:300:500:600);

.h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 32px;
}

.p {
    font-family: 'Lato', sans-serif;
    letter-spacing: 1px;
    font-size: 14px;
    color: #333333;
}

.header {
    position: relative;
    text-align: center;
    background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
    color: white;
}


.inner-header {
    height: 20vh;
    width: 100%;
    margin: 0;
    padding: 0;
}

.flex {
    /*Flexbox for containers*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.bottom-container {
    display: flex;
    align-items: center;
}


.waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px;
    /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
}

.content {
    position: relative;
    height: 100%;
    text-align: center;
    background-color: white;
}

/* Animation */

.parallax>use {
    animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax>use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}

.parallax>use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}

.parallax>use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}

.parallax>use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}

@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }

    100% {
        transform: translate3d(85px, 0, 0);
    }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
    .form {
        max-width: 80vh;
    }

    .waves {
        height: 40px;
        min-height: 40px;
    }

    .content {
        height: 70vh;
    }

    .h1 {
        font-size: 24px;
    }
}

.form {
    display: flex;
    width: 80vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    margin: 10px;
}

.input {
    width: 80%;
    padding: 8px;
    margin: 10px;
    height: 32px;
    font-size: 1rem;
    border: none;
    box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 20%);
    border-radius: 25px;
    outline: none;
    text-align: center;

}

.login-btn {
    font-size: 1rem;
    border: none;
    height: 44px;
    width: 40%;
    margin: 20px 10px;
    border-radius: 25px;
    background-color: #3A5FBB;
    color: #ffffff
}

.login-btn:hover {
    box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 30%);

}